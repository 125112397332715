import React from "react";
import "./Newton.scss";
import data from "./data.json";
import Card from "../../Component/Card/card";
import Newton7images from "../../Media/Sanctuary/Newton/Newton 7/Newton7";
import Newton8images from "../../Media/Sanctuary/Newton/Newton 8/Newton8";

const imageMap = {
  Newton7images,
  Newton8images,

  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="Newton" className="produk">
      <h1>Welcome to Newton Springs</h1>
      <h2>Where Lakeside Living Meets Luxury</h2>
      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
