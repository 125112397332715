import Surrounding1 from '../../Media/Surrounding/9.webp'
import Surrounding2 from '../../Media/Surrounding/10.webp'
import Surrounding3 from '../../Media/Surrounding/11.webp'
import Surrounding4 from '../../Media/Surrounding/12.webp'
import Surrounding5 from '../../Media/Surrounding/13.webp'
import Surrounding6 from '../../Media/Surrounding/14.webp'
import Surrounding7 from '../../Media/Surrounding/15.webp'
import Surrounding8 from '../../Media/Surrounding/16.webp'
import Surrounding9 from '../../Media/Surrounding/17.webp'
import Surrounding10 from '../../Media/Surrounding/18.webp'
import Surrounding11 from '../../Media/Surrounding/19.webp'





const Surroundingimages =[
    Surrounding1,Surrounding2,Surrounding3,Surrounding4,Surrounding5,Surrounding6,Surrounding7,Surrounding8,Surrounding9,Surrounding10,Surrounding11
]

export default Surroundingimages;