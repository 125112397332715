import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar";
import Banner from "../Section/Banner/banner";
import About from "../Section/About/about";
import Promo from "../Section/Promo/promo.jsx";
import Newton from "../Section/Newton/Newton";
import Orchard from "../Section/Orchard/Orchard";
import Fasilitas from "../Section/Fasilitas/fasilitas.jsx";
import Surrounding from "../Section/Surrounding Area/surrounding.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";
import Scrolltohashelement from "../Pages/ScrollToHashElement";
import tombol from "../Media/tombol.webp";
const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287823771332&text=Halo%20Monica,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20The%20Sanctuary%20Sentul%20https://thesanctuarysentul.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <Scrolltohashelement />
      <Navbar />
      <Banner />
      <About />
      <Promo />
      <Newton />
      <Orchard />
      <Fasilitas />
      <Surrounding />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
