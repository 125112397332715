import Orchard121 from "../../../../Media/Sanctuary/Orchard/Orchard 12/Orchard 12 (1).webp"
import Orchard122 from "../../../../Media/Sanctuary/Orchard/Orchard 12/Orchard 12 (2).webp"
import Orchard123 from "../../../../Media/Sanctuary/Orchard/Orchard 12/Orchard 12 (3).webp"
import Orchard124 from "../../../../Media/Sanctuary/Orchard/Orchard 12/Orchard 12 (4).webp"
import Orchard125 from "../../../../Media/Sanctuary/Orchard/Orchard 12/Orchard 12 (5).webp"
import Orchard126 from "../../../../Media/Sanctuary/Orchard/Orchard 12/Orchard 12 (6).webp"
import Orchard127 from "../../../../Media/Sanctuary/Orchard/Orchard 12/Orchard 12 (7).webp"
import Orchard128 from "../../../../Media/Sanctuary/Orchard/Orchard 12/Orchard 12 (8).webp"
import Orchard129 from "../../../../Media/Sanctuary/Orchard/Orchard 12/Orchard 12 (9).webp"

const Orchard12images =[
    Orchard121,Orchard122,Orchard123,Orchard124,Orchard125,Orchard126,Orchard127,Orchard128,Orchard129
    ]
    
    export default Orchard12images;
    