import Newton81 from "../../../../Media/Sanctuary/Newton/Newton 8/Newton 8 (1).webp"
import Newton82 from "../../../../Media/Sanctuary/Newton/Newton 8/Newton 8 (2).webp"
import Newton83 from "../../../../Media/Sanctuary/Newton/Newton 8/Newton 8 (3).webp"
import Newton84 from "../../../../Media/Sanctuary/Newton/Newton 8/Newton 8 (4).webp"
import Newton85 from "../../../../Media/Sanctuary/Newton/Newton 8/Newton 8 (5).webp"
import Newton86 from "../../../../Media/Sanctuary/Newton/Newton 8/Newton 8 (6).webp"
import Newton87 from "../../../../Media/Sanctuary/Newton/Newton 8/Newton 8 (7).webp"


const Newton8images =[
    Newton81,Newton82,Newton83,Newton84,Newton85,Newton86,Newton87
    ]
    
    export default Newton8images;
    