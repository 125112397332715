
import Orchard82 from "../../../../Media/Sanctuary/Orchard/Orchard 8/Orchard 8 (2).webp"
import Orchard83 from "../../../../Media/Sanctuary/Orchard/Orchard 8/Orchard 8 (3).webp"
import Orchard84 from "../../../../Media/Sanctuary/Orchard/Orchard 8/Orchard 8 (4).webp"
import Orchard85 from "../../../../Media/Sanctuary/Orchard/Orchard 8/Orchard 8 (5).webp"
import Orchard86 from "../../../../Media/Sanctuary/Orchard/Orchard 8/Orchard 8 (6).webp"
import Orchard87 from "../../../../Media/Sanctuary/Orchard/Orchard 8/Orchard 8 (7).webp"
import Orchard88 from "../../../../Media/Sanctuary/Orchard/Orchard 8/Orchard 8 (8).webp"

const Orchard8images =[
    Orchard82,Orchard83,Orchard84,Orchard85,Orchard86,Orchard87,Orchard88
    ]
    
    export default Orchard8images;
    