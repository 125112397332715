import React from "react";
import "./fasilitas.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Fasilitasimages from "./fasilitas";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings1 = {
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const About = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287823771332&text=Halo%20Monica,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20The%20Sanctuary%20Sentul%20(Fasilitas)%20https://thesanctuarysentul.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="about">
      <div id="fasilitas" className="container-about">
        <h1>Living Life With Everything You Need Around the Corner</h1>
        <hr />
        <div className="container-desk">
          <div className="desk">
            Dalam meningkatkan kenyamanan penghuni, The Sanctuary menyiapkan
            fasilitas yang dapat digunakan oleh seluruh penghuni, seperti :
          </div>
        </div>
        <div className="container-benefit">
          <div className="benefit-list">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>&nbsp;&nbsp;One Gate System</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Clubhouse in Every Cluster
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Children Playground
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>&nbsp;&nbsp;Swimming Pool</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Security 24 Hours
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>&nbsp;&nbsp;Tennis Court</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>&nbsp;&nbsp;Basketball Court</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>&nbsp;&nbsp;Jogging Track</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Gym Indoor & Outdoor
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>&nbsp;&nbsp;Garden Park</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Enginering 24 Hours
              </span>
            </div>
          </div>
        </div>
        <div className="button">
          <div className="containerwa">
            <button
              className="button-penawaran"
              onClick={handleConsultationClick}>
              Learn More
            </button>
          </div>
        </div>
      </div>

      <div className="slider-regia">
        <Slider {...settings1}>
          {Fasilitasimages.map((image, index) => (
            <img className="imagecard" key={index} src={image} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default About;
