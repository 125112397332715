import React from "react";
import "./about.scss";
import logo from "../../Media/Logo Sanctuary.png";

const aboutparkspring = () => {
  return (
    <div id="about" className="container-aboutparkspring">
      <div className="logo-aboutparkspring">
        <img
          className="gambar-logoparkspring"
          src={logo}
          alt="logo-parkspring"
        />
      </div>
      <div className="container-deskripsiparkspring">
        <h1>Premium Living By The Mountain </h1>
        <div className="deskripsi-aboutparkspring">
          The Sanctuary Sentul adalah sebuah kawasan hunian eksklusif yang
          terletak di tengah keindahan alam Sentul, Bogor. The Sanctuary
          Collection di Sentul adalah proyek perumahan mewah yang dikembangkan
          oleh kolaborasi tiga perusahaan ternama Perennial Holdings Private
          Limited, Qingjian Realty, dan PT Cipta Harmoni Lestari. Dikembangkan
          dengan konsep modern dan berkelas, perumahan ini menawarkan lingkungan
          yang nyaman, hijau, dan asri, jauh dari hiruk-pikuk kota, namun tetap
          mudah dijangkau. Dikelilingi oleh pegunungan, udara segar, dan
          pemandangan alam yang memukau, The Sanctuary Sentul memberikan suasana
          tenang yang ideal bagi keluarga.
        </div>
      </div>
    </div>
  );
};

export default aboutparkspring;
