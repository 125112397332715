import Orchard101 from "../../../../Media/Sanctuary/Orchard/Orchard 10/Orchard 10 (1).webp"
import Orchard102 from "../../../../Media/Sanctuary/Orchard/Orchard 10/Orchard 10 (2).webp"
import Orchard103 from "../../../../Media/Sanctuary/Orchard/Orchard 10/Orchard 10 (3).webp"
import Orchard104 from "../../../../Media/Sanctuary/Orchard/Orchard 10/Orchard 10 (4).webp"


const Orchard10images =[
    Orchard101,Orchard102,Orchard103,Orchard104
    ]
    
    export default Orchard10images;
    