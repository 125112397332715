import Newton71 from "../../../../Media/Sanctuary/Newton/Newton 7/Newton 7 (1).webp"
import Newton72 from "../../../../Media/Sanctuary/Newton/Newton 7/Newton 7 (2).webp"
import Newton73 from "../../../../Media/Sanctuary/Newton/Newton 7/Newton 7 (3).webp"
import Newton74 from "../../../../Media/Sanctuary/Newton/Newton 7/Newton 7 (4).webp"
import Newton75 from "../../../../Media/Sanctuary/Newton/Newton 7/Newton 7 (5).webp"
import Newton76 from "../../../../Media/Sanctuary/Newton/Newton 7/Newton 7 (6).webp"
import Newton77 from "../../../../Media/Sanctuary/Newton/Newton 7/Newton 7 (7).webp"


const Newton7images =[
    Newton71,Newton72,Newton73,Newton74,Newton75,Newton76,Newton77
    ]
    
    export default Newton7images;
    