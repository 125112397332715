import React from "react";
import "./Orchard.scss";
import data from "./data.json";
import Card from "../../Component/Card/card";
import Orchard8images from "../../Media/Sanctuary/Orchard/Orchard 8/Orchard8";
import Orchard10images from "../../Media/Sanctuary/Orchard/Orchard 10/Orchard10";
import Orchard12images from "../../Media/Sanctuary/Orchard/Orchard 12/Orchard12";

const imageMap = {
  Orchard8images,
  Orchard10images,
  Orchard12images,
  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="Orchard" className="produk">
      <h1>Welcome to Orchard Riviera</h1>
      <h2>Lakeside Living: The Perfect Home, the Perfect View</h2>
      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
